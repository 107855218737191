import {useCallback, useContext, useEffect, useState} from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { DrawerContext } from 'src/contexts/DrawerContext';

import {Box, Drawer, Hidden, IconButton, styled, Typography} from '@mui/material';

import DrawerMenu from './DrawerMenu';
import menuItems from "./DrawerMenu/items";
import {ReactComponent as CloseIcon} from "../../../assets/icons/close-new.svg";

const DrawerWrapper = styled(Drawer)(
    ({theme}) => `
    z-index: 100000000010;
        div.MuiDrawer-paper {
        width: 100%;
        }
        `
);

const DrawerContainerWrapper = styled(Box)(
    ({ theme }) => `
        width: 100%;
        color: ${theme.drawer.textColor};
        background: #FFF;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
        display: none;
        }
`
);

const TopSection = styled(Box)(
    ({ theme }) => `
        display: flex;
        height: 82px;
        align-items: center;
        margin: 0 ${theme.spacing(2)} ${theme.spacing(2)};
        border-bottom: ${theme.drawer.dividerBg} solid 1px;
`
);

const HeaderButton = styled(IconButton)(
    () => `
        padding: 0;
        
        & svg {
            height: 42px;
            width: 42px;
        }
    `
)

function DrawerIndex() {
    const { drawerToggle, toggleDrawer } = useContext(DrawerContext);
    const closeDrawer = () => toggleDrawer();

    return (
        <>
            <Hidden lgDown>
                <DrawerContainerWrapper>
                    <Scrollbars autoHide>
                        <TopSection>
                        </TopSection>
                        <DrawerMenu menuItems={menuItems} />
                    </Scrollbars>
                </DrawerContainerWrapper>
            </Hidden>
            <Hidden lgUp>
                <DrawerWrapper
                    anchor="left"
                    open={drawerToggle}
                    onClose={closeDrawer}
                    variant="temporary"
                    elevation={9}
                >
                    <DrawerContainerWrapper>
                        <Scrollbars autoHide>
                            <TopSection sx={{zIndex: 10, position: "sticky", top: 0, margin: 0, borderBottom: "none", background: "#ffffff"}}>
                                <Box px={"20px"} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} sx={{height: "100%", width: "100%"}}>
                                    <HeaderButton color="primary" onClick={closeDrawer}>
                                        {<CloseIcon/>}
                                    </HeaderButton>
                                </Box>
                            </TopSection>
                            <DrawerMenu menuItems={menuItems} />
                        </Scrollbars>
                    </DrawerContainerWrapper>
                </DrawerWrapper>
            </Hidden>
        </>
    );
}

export default DrawerIndex;
