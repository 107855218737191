import {useContext} from 'react';

import {Avatar, Box, IconButton, styled, Typography} from '@mui/material';
import {ReactComponent as BackIcon} from '../../../assets/icons/chevron-left.svg';
import {ReactComponent as MenuIcon} from '../../../assets/icons/menu.svg';
import {ReactComponent as MenuAltIcon} from '../../../assets/icons/menu-alt.svg';
import {DrawerContext} from 'src/contexts/DrawerContext';
import {HeaderContext} from "../../../contexts/HeaderContext";
import HeaderVariant from "../../../enums/HeaderVariant";
import {ReactComponent as CloseIcon} from "../../../assets/icons/close.svg";
import {ReactComponent as LogoAlt} from "../../../assets/images/logo-alt.svg";
import {ReactComponent as AccountPlaceholderImage} from "../../../assets/icons/circle-user.svg";
import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router";

const HeaderButton = styled(IconButton)(
    () => `
        padding: 0;
        
        & svg {
            height: 42px;
            width: 42px;
        }
    `
)

const HeaderWrapper = styled(Box)(
    ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.md}px) {
            & .menu-btn {
                display: none;
            }
            
            & .logo-container {
                padding-left: 0;
                margin-left: 0;
            }
        }
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            & .btns-container {
                max-width: 872px;
                margin: 0 auto;
                left: 0;
                padding-left: 0;
                padding-right: 0;
            }
            
            & .logo-container {
                padding-left: 0;
                margin-left: -1px;
            }
        }
`
);

function Header() {
    const auth = useAuth();
    const navigate = useNavigate();

    const { variant, label, actionLeft, actionRight, onLogoClick, onAccountClickCallback } = useContext(HeaderContext);
    const { drawerToggle, toggleDrawer } = useContext(DrawerContext);

    const onAccountPress = ():void => {
        if (auth.isAuthenticated && auth.user) {
            if (window.location.href.includes("account")) {
                return;
            }

            return navigate("/account");
        }

        if (window.location.href.endsWith("login")) {
            return;
        }

        return navigate("/login");
    }

    const getImage = () => {
        if (auth.isAuthenticated && auth.user) {
            if (auth.user.avatar && auth.user.avatar.length > 5) {
                return (
                    <img key={`${auth.user.avatar}-0`} style={{
                        cursor: "pointer",
                        flexShrink: 0,
                        borderRadius: "50%",
                        width: "42px",
                        height: "42px",
                        objectFit: "cover",
                        marginBottom: "1px"
                    }} src={`${auth.user.avatar}?nocache=${Math.floor(Math.random() * 1000)}`}/>
                )
            }

            return (
                    <Box sx={{cursor: "pointer"}} height={"42px"} width={"42px"} m={"auto"}>
                        <Avatar sx={{background: "#1F57B5", height: "100%", width: "100%", aspectRatio: "inherit"}} children={<Typography sx={{cursor: "pointer"}} fontSize={"12px"}>{`${auth.user.firstName.charAt(0)}${auth.user.lastName.charAt(0)}`}</Typography>}/>
                    </Box>
                )
        }

        return (
            <AccountPlaceholderImage/>
        )
    }

    const getVariant = () => {
        switch(variant) {
            case HeaderVariant.Back:
                return [<HeaderButton className={"menu-btn"} color="primary" onClick={() => {actionLeft()}}>
                    <BackIcon/>
                </HeaderButton>]
            case HeaderVariant.Back_Label:
                return [<HeaderButton className={"menu-btn"} color="primary" onClick={() => {actionLeft()}}>
                    <BackIcon/>
                </HeaderButton>]
            case HeaderVariant.Back_Account:
                return [<HeaderButton className={"menu-btn"} color="primary" onClick={() => {actionLeft()}}>
                    <BackIcon/>
                </HeaderButton>,
                    <HeaderButton disableTouchRipple={true} disableRipple={true} disableFocusRipple={true} color="primary" onClick={() => onAccountPress()}>
                        {getImage()}
                    </HeaderButton>]
            case HeaderVariant.Menu_Account:
                return [<HeaderButton className={"menu-btn"} color="primary" onClick={toggleDrawer}>
                    {!drawerToggle ? <MenuAltIcon/> : <CloseIcon/>}
                </HeaderButton>,
                    <HeaderButton color="primary" onClick={() => onAccountPress()}>
                        {getImage()}
                    </HeaderButton>]
            case HeaderVariant.Menu_Account_Book:
                return [<HeaderButton className={"menu-btn"} color="primary" onClick={toggleDrawer}>
                    {!drawerToggle ? <MenuAltIcon/> : <CloseIcon/>}
                </HeaderButton>,
                    <HeaderButton color="primary" onClick={() => {onAccountClickCallback()}}>
                        {getImage()}
                    </HeaderButton>]
            case HeaderVariant.Menu_Account_Home:
                return [<HeaderButton className={"menu-btn"} color="primary" onClick={toggleDrawer}>
                    {!drawerToggle ? <MenuAltIcon/> : <CloseIcon/>}
                </HeaderButton>,
                    <HeaderButton color="primary" onClick={() => {onAccountPress()}}>
                        {getImage()}
                    </HeaderButton>]
            default:
                return [<HeaderButton className={"menu-btn"} color="primary" onClick={toggleDrawer}>
                    {!drawerToggle ? <MenuIcon/> : <CloseIcon/>}
                </HeaderButton>,
                    <HeaderButton color="primary" onClick={() => onAccountPress()}>
                        {getImage()}
                    </HeaderButton>]
        }
    }

    const headerVariant = getVariant();

    return (
        <HeaderWrapper id={"header-main"} sx={{zIndex: 1000000000, padding: "0", borderBottom: (variant !== HeaderVariant.Base ? "1px solid #E2EEFF" : "1px solid transparent"), background: "#FFF"}} display="flex" alignItems="center">
            <Box className={"btns-container"} px={"20px"} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} sx={{height: "100%", width: "100%"}}>
                {headerVariant[0]}
                {label && (
                    <>
                    <Box className={"logo-container"} onClick={() => navigate("/")} pl={"15px"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"} sx={{width: "100%", cursor: "pointer"}}>
                        {variant === HeaderVariant.Base ?
                            <LogoAlt/>
                            :
                            variant === HeaderVariant.Back || variant === HeaderVariant.Back_Account || variant === HeaderVariant.Menu_Account || variant === HeaderVariant.Menu_Account_Book || variant === HeaderVariant.Menu_Account_Home ?
                                <LogoAlt/>
                                :
                            <Typography color="#0FA9ED" variant="body1" component="p"
                                        sx={{fontSize: "16px", lineHeight: "18px", textAlign: 'center', fontWeight: "medium"}}>
                                {label}
                            </Typography>
                        }
                    </Box>
                        <Box className={"header-menu-lg"} px={"15px"} alignItems={"center"} flexDirection={"row"}>
                            <Box className={"header-menu-element-container"}>
                                <Typography className={"header-menu-element"} whiteSpace={"nowrap"} color="#1F57B5" variant="body1" component="p"
                                            sx={{textDecorationLine: "underline", fontSize: "14px", lineHeight: "21px", fontWeight: 700}}>
                                    {"Prenota"}
                                </Typography>
                            </Box>
                            <Box onClick={() => window.open("https://strutture.beachnow.it", "_self")} className={"header-menu-element-container"}>
                                <Typography color="#23262F" className={"header-menu-element"} whiteSpace={"nowrap"} variant="body1" component="p"
                                            sx={{fontSize: "14px", lineHeight: "21px", fontWeight: 700}}>
                                    {"Sei un gestore?"}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                )}
                {headerVariant.length > 1 ? headerVariant[1] : <Box sx={{opacity: 0}}>{headerVariant[0]}</Box>}
            </Box>
        </HeaderWrapper>
    );
}

export default Header;
