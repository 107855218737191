import {ReactNode} from 'react';

export interface MenuItem {
    link?: string;
    active?: boolean;
    overrideRouter?: boolean;
    openInBlank?: boolean;
    icon?: ReactNode;
    items?: MenuItem[];
    name: string;
    buttonClass?: string;
}

export interface MenuItems {
    items: MenuItem[];
    heading: string;
}

const menuItems: MenuItems[] = [
    {
        heading: '',
        items: [
            {
                name: 'Prenota',
                link: '/'
            },
            {
                name: 'Sei un gestore?',
                openInBlank: true,
                link: 'https://strutture.beachnow.it'
            }
        ]
    },
    {
        heading: '',
        items: [
            {
                name: 'Account',
                link: '/account',
                buttonClass: 'menu-item-small'
            },
            {
                name: 'Registrati',
                link: '/sign_up',
                buttonClass: 'menu-item-small'
            }
        ]
    }
];

export default menuItems;
