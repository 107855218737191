/* eslint-disable no-bitwise */
export const JWT_SECRET = "pYECdOlVGUeFei9lXCGtx+llWjQ0mLYml1WjOCO2V7dLTsnrnbg9ILmgOYey5Ii/z3Ul8NUqHaBjGTrdusJKAZ2CB8K4VItBKu4yrHBB6YyUiTe8wKi58zjJZ9ypo3Fq1c4/UAOblIHk9bMQjj3UvRrlpXICO1o5xwkJ+fS7PKXis1OyW8xY+Z11A3HnD+kIZBILX1Nd3PppCIBKwbGIhQVVY2qyNNnPlpPcHPI+3aThjdCATQoaKxVoryp0KyN3Xl5T4ZuuvKJoWIuoWEtAkQtscHhUrlaiB3fqqwxgBz+2ThI8gBjWuWo4CnGw41Gxth9h27KkEv4WFwKJLrM35A==";
export const JWT_EXPIRES_IN = 3600 * 24 * 2;

export const sign = (
  payload: Record<string, any>,
  privateKey: string,
  header: Record<string, any>
) => {
  const now = new Date();
  header.expiresIn = new Date(now.getTime() + header.expiresIn);
  const encodedHeader = btoa(JSON.stringify(header));
  const encodedPayload = btoa(JSON.stringify(payload));
  const signature = btoa(
      Array
          .from(encodedPayload)
          .map((item, key) => (
              String.fromCharCode(item.charCodeAt(0) ^ JWT_SECRET[key % JWT_SECRET.length].charCodeAt(0))
          ))
          .join('')
  );

  return `${encodedHeader}.${encodedPayload}.${signature}`;
};

export const decode = (token: string): any => {
  const [encodedHeader, encodedPayload, signature] = token.split('.');
  const header = JSON.parse(atob(encodedHeader));
  const payload = JSON.parse(atob(encodedPayload));
  const now = new Date();

  if (now < header.expiresIn) {
    throw new Error('Expired token');
  }

  const verifiedSignature = btoa(
      Array
          .from(encodedPayload)
          .map((item, key) => (
              String.fromCharCode(item.charCodeAt(0) ^ JWT_SECRET[key % JWT_SECRET.length].charCodeAt(0))
          ))
          .join('')
  );

  if (verifiedSignature !== signature) {
    throw new Error('Invalid signature');
  }

  return payload;
};

export const verify = (
  token: string,
  privateKey: string
): Record<string, any> => {
  const [encodedHeader, encodedPayload, signature] = token.split('.');
  const header = JSON.parse(atob(encodedHeader));
  const payload = JSON.parse(atob(encodedPayload));
  const now = new Date();

  if (now < header.expiresIn) {
    throw new Error('The token is expired!');
  }

  const verifiedSignature = btoa(
      Array
          .from(encodedPayload)
          .map((item, key) => (
              String.fromCharCode(item.charCodeAt(0) ^ privateKey[key % privateKey.length].charCodeAt(0))
          ))
          .join('')
  );

  if (verifiedSignature !== signature) {
    throw new Error('The signature is invalid!');
  }

  return payload;
};
