import {Box, Container, Grid, IconButton, Typography} from "@mui/material";
import {ReactComponent as Logo} from "../../assets/images/logo-alt.svg";
import {ReactComponent as FbIcon} from "../../assets/icons/facebook.svg";
import {ReactComponent as IgIcon} from "../../assets/icons/instagram.svg";
import Spacer from "../Spacer";

function Footer(props) {

    return (
        <>
            <Box id={"footer-main"} mt={props && props.marginTop ? `${props.marginTop}px` : 0} sx={{background: "#E2EEFF"}}>
            <Container maxWidth={"lg"} sx={{padding: "0 !important"}}>
                <Box className={"footer-hide-on-desktop"} textAlign={"left"} py={"30px"} px={"20px"}>
                    <Box>
                        <Logo/>
                    </Box>
                    <Typography pt={"13px"} variant={"body1"} color={"#23262F"} fontSize={"14px"} lineHeight={"21px"} fontWeight={600}>
                        Il nuovo modo di andare al mare!
                    </Typography>
                    <Spacer spaceY={20} height={1}/>
                    <Box>
                        <Grid container spacing={0} alignItems={"center"}>
                            <Grid item xs={6}>
                                <Typography variant={"body1"} color={"#23262F"} fontSize={"14px"} lineHeight={"21px"} fontWeight={700}>
                                    Supporto
                                </Typography>
                                <Typography pt={"5px"} variant={"body1"} fontSize={"14px"} lineHeight={"21px"}>
                                    <a style={{fontWeight: 500, color: "#1F57B5", cursor: "pointer"}} href={"mailto:info@beachnow.it"}>info@beachnow.it</a>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box display={"flex"} columnGap={"20px"} justifyContent={"flex-end"}>
                                    <Box>
                                        <FbIcon height={24} width={24}/>
                                    </Box>
                                    <Box>
                                        <IgIcon height={24} width={24}/>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Spacer spaceY={20} height={1}/>
                    <Box display={"flex"} columnGap={"15px"}>
                        <Typography variant={"body1"} fontSize={"12px"} lineHeight={"18px"}>
                            <a style={{fontWeight: 500, color: "#1F57B5", cursor: "pointer"}} href={"https://strutture.beachnow.it/termini-e-condizioni/"} target={"_blank"}>Termini e condizioni</a>
                        </Typography>
                        <Typography variant={"body1"} fontSize={"12px"} lineHeight={"18px"}>
                            <a style={{fontWeight: 500, color: "#1F57B5", cursor: "pointer"}} href={"https://strutture.beachnow.it/privacy-policy/"} target={"_blank"}>Privacy e Cookie Policy</a>
                        </Typography>
                    </Box>
                    <Box pt={"20px"}>
                        <Typography variant={"body1"} color={"#23262F"} fontSize={"12px"} lineHeight={"18px"} fontWeight={500}>
                            &copy; 2023 Beachnow.it Srls<br/>
                            P.IVA 02736150695 - REA CH - 418492
                        </Typography>
                    </Box>
                </Box>
                {/* DESKTOP */}
                <Box className={"footer-hide-on-mobile"} textAlign={"left"} py={"40px"} px={"20px"}>
                    <Grid container spacing={0}>
                        <Grid item xs={7.5}>
                            <Box>
                                <Logo/>
                            </Box>
                            <Typography pt={"13px"} variant={"body1"} color={"#23262F"} fontSize={"14px"} lineHeight={"21px"} fontWeight={600}>
                                Il nuovo modo di andare al mare!
                            </Typography>
                        </Grid>
                        <Grid item xs={4.5}>
                            <Grid container spacing={0} alignItems={"center"}>
                                <Grid item xs={6}>
                                    <Typography variant={"body1"} color={"#23262F"} fontSize={"14px"} lineHeight={"21px"} fontWeight={700}>
                                        Supporto
                                    </Typography>
                                    <Typography pt={"5px"} variant={"body1"} fontSize={"14px"} lineHeight={"21px"}>
                                        <a style={{fontWeight: 500, color: "#1F57B5", cursor: "pointer"}} href={"mailto:info@beachnow.it"}>info@beachnow.it</a>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display={"flex"} columnGap={"20px"} justifyContent={"flex-end"}>
                                        <Box sx={{cursor: "pointer"}} onClick={() => window.open("https://www.facebook.com/beachnow.it/", "_blank")}>
                                            <FbIcon height={24} width={24}/>
                                        </Box>
                                        <Box sx={{cursor: "pointer"}} onClick={() => window.open("https://www.instagram.com/beachnow.it/", "_blank")}>
                                            <IgIcon height={24} width={24}/>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Spacer spaceY={20} height={1}/>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <Box display={"flex"} columnGap={"15px"}>
                                <Typography variant={"body1"} fontSize={"12px"} lineHeight={"18px"}>
                                    <a style={{fontWeight: 500, color: "#1F57B5", cursor: "pointer"}} href={"https://strutture.beachnow.it/termini-e-condizioni/"} target={"_blank"}>Termini e condizioni</a>
                                </Typography>
                                <Typography variant={"body1"} fontSize={"12px"} lineHeight={"18px"}>
                                    <a style={{fontWeight: 500, color: "#1F57B5", cursor: "pointer"}} href={"https://strutture.beachnow.it/privacy-policy/"} target={"_blank"}>Privacy e Cookie Policy</a>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography textAlign={"right"} variant={"body1"} color={"#23262F"} fontSize={"12px"} lineHeight={"18px"} fontWeight={500}>
                                &copy; 2023 Beachnow.it Srls&nbsp;&nbsp;P.IVA 02736150695 - REA CH - 418492
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            </Box>
        </>
    )
}

export default Footer;
