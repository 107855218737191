import React from 'react';
import './App.css';
import {useRoutes} from "react-router";
import routes from "./router";
import ThemeProvider from "./theme/ThemeProvider";
import {CssBaseline} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {LocalizationProvider} from "@mui/lab";
import itLocale from "date-fns/locale/it";

function App() {

  const content = useRoutes(routes);

  return (
          <ThemeProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
                  <CssBaseline/>
                  {content}
              </LocalizationProvider>
          </ThemeProvider>
  );
}

export default App;
