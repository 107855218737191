import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import {DrawerProvider} from "./contexts/DrawerContext";
import {HeaderProvider} from "./contexts/HeaderContext";
import {AuthProvider} from "./contexts/JWTAuthContext";
import {LoadingOverlayProvider} from "./contexts/LoadingOverlayContext";
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <HelmetProvider>
        <LoadingOverlayProvider>
        <DrawerProvider>
            <HeaderProvider>
                <BrowserRouter>
                    <AuthProvider>
                        <App/>
                    </AuthProvider>
                </BrowserRouter>
            </HeaderProvider>
        </DrawerProvider>
        </LoadingOverlayProvider>
    </HelmetProvider>,
  document.getElementById('root')
);
