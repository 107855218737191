import {FC} from "react";
import {Divider} from "@mui/material";

interface SpacerProps {
    height?: number;
    spaceY: number;
    spaceX?: number;
    borderColor?: string;
}

const Spacer:FC<SpacerProps> = ({height = 2, spaceY, spaceX = 0, borderColor = "#AED0FF"}) => {

    return (
        <Divider sx={{height: `${height}px`, background: `${borderColor} !important`, margin: `${spaceY}px ${spaceX}px`}}/>
    )
}

export default Spacer;
