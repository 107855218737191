enum HeaderVariant {
    Base = 0,
    Back_Account = 1,
    Menu_Account = 2,
    Menu_Account_Home = 3,
    Menu_Account_Book = 6,
    Back = 4,
    Back_Label = 5
}

export default HeaderVariant;
