import {FC, useEffect} from "react";
import {Box, CircularProgress} from "@mui/material";
import NProgress from "nprogress";

interface LoadingOverlayProps {
    active: boolean;
}

const LoadingOverlay:FC<LoadingOverlayProps> = ({active}) => {

    useEffect(() => {
        NProgress.start();

        return () => {
            NProgress.done();
        };
    }, []);

    return (
        <>
            <Box zIndex={100000} height={"100%"} width={"100%"} position={"fixed"} top={0} left={0} display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{background: "rgba(255, 255, 255, 0.75)",
                transition: "opacity 0.25s",
                opacity: `${active ? '1' : '0'}`,
                pointerEvents: (active ? "all" : "none")}}>
                {active && (
                    <CircularProgress size={64} disableShrink thickness={3} />
                )}
            </Box>
        </>
    )
}

export default LoadingOverlay;
