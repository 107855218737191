import { FC, useState, createContext } from 'react';
type DrawerContext = { drawerToggle: any; toggleDrawer: () => void };

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DrawerContext = createContext<DrawerContext>(
    {} as DrawerContext
);

export const DrawerProvider: FC = ({ children }) => {
    const [drawerToggle, setDrawerToggle] = useState(false);
    const toggleDrawer = () => {
        setDrawerToggle(!drawerToggle);
    };

    return (
        <DrawerContext.Provider value={{ drawerToggle, toggleDrawer }}>
            {children}
        </DrawerContext.Provider>
    );
};
