import {createContext, FC, ReactNode, useEffect, useReducer} from 'react';
import axios, {sendGetRequest, sendPostRequest} from 'src/utils/axios';
import {decode, JWT_EXPIRES_IN, JWT_SECRET, sign, verify} from 'src/utils/jwt';
import PropTypes from 'prop-types';
import {User} from "../models/user";
import AuthErrorCode from "../enums/AuthErrorCode";
import authErrorCode from "../enums/AuthErrorCode";

interface AuthState {
    isInitialized: boolean;
    isAuthenticated: boolean;
    user: User | null;
    code: AuthErrorCode;
}

interface AuthContextValue extends AuthState {
    method: 'JWT';
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
}

interface AuthProviderProps {
    children: ReactNode;
}

type InitializeAction = {
    type: 'INITIALIZE';
    payload: {
        isAuthenticated: boolean;
        user: User | null;
        code: AuthErrorCode;
    };
};

type LoginAction = {
    type: 'LOGIN';
    payload: {
        isAuthenticated: boolean,
        user: User,
        code: AuthErrorCode
    }
};

type LogoutAction = {
    type: 'LOGOUT';
};

type Action = InitializeAction | LoginAction | LogoutAction;

const initialAuthState: AuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    code: AuthErrorCode.DEFAULT
};

const setSession = (accessTkSignature: string | null, accessToken: string | null): void => {
    if (accessToken && accessTkSignature) {
        localStorage.setItem('clientAccessToken', JSON.stringify({token: accessToken, signature: accessTkSignature}));
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('clientAccessToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

const handlers: Record<string, (state: AuthState, action: Action) => AuthState> = {
    INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
        const { isAuthenticated, user, code } = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
            code: code
        };
    },
    LOGIN: (state: AuthState, action: LoginAction): AuthState => {
        const { user, code, isAuthenticated } = action.payload;

        return {
            ...state,
            isAuthenticated: isAuthenticated,
            user: user,
            code: code
        };
    },
    LOGOUT: (state: AuthState): AuthState => ({
        ...state,
        isAuthenticated: false,
        user: null,
        code: AuthErrorCode.DEFAULT
    })
};

const reducer = (state: AuthState, action: Action): AuthState => (
    handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
    ...initialAuthState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            try {
                const accessToken = window.localStorage.getItem('clientAccessToken');

                const tkFromJson = JSON.parse(accessToken);

                if (accessToken && tkFromJson && verify(tkFromJson.signature, JWT_SECRET)) {
                    setSession(tkFromJson.signature, tkFromJson.token);

                    const userId = decode(tkFromJson.signature).id;

                    const response = await sendGetRequest(`users/login/${userId}`, {headers: {"x-access-token": tkFromJson.token}}) as User;
                    const { user, code } = response;

                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: true,
                            user,
                            code: code
                        }
                    });
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                            code: AuthErrorCode.DEFAULT
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                        code: AuthErrorCode.DEFAULT
                    }
                });
            }
        };

        initialize();
    }, []);

    const login = async (email: string, password: string): Promise<void> => {
        const response = await sendPostRequest<{accessToken: string, user: User, code: AuthErrorCode}>("auth/user/login", {
            email,
            password
        })

        const { accessToken, user, code } = response;

        if (code === AuthErrorCode.INVALID) {
            dispatch({
                type: 'LOGIN',
                payload: {
                    isAuthenticated: false,
                    user: null,
                    code: code
                }
            });
        }

        const accessTkSig = sign({id: user.id}, JWT_SECRET, {
            expiresIn: JWT_EXPIRES_IN
        });

        setSession(accessTkSig, accessToken);
        dispatch({
            type: 'LOGIN',
            payload: {
                isAuthenticated: true,
                user,
                code: code
            }
        });
    };

    const logout = async (): Promise<void> => {
        setSession(null, null);
        dispatch({ type: 'LOGOUT' });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default AuthContext;
