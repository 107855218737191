import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';

const axiosInt = axios.create();

// export const API_SERVER_BASE = "http://localhost:19000"
// export const API_SERVER_BASE = "https://api2.beachnow.it"
export const API_SERVER_BASE = "https://api.beachnow.it"

const API_SERVER = `${API_SERVER_BASE}/api/`;

export const sendDeleteRequest = async(request) => {
    try {
        const resp = await axios.delete(`${API_SERVER}${request}`);
        return resp.data;
    } catch (err) {
        console.error(err);
    }
}

export const sendGetRequest = async<T>(request, headers?) => {
    try {
        const resp = (headers ?
                await axios.get<T>(`${API_SERVER}${request}`, headers) :
                await axios.get<T>(`${API_SERVER}${request}`)
        );

        return resp.data;
    } catch (err) {
        console.error(err);
    }
}

export const sendPostRequest = async<T=any>(request, body) => {
    try {
        const resp = await axios.post<T>(`${API_SERVER}${request}`, body);
        return resp.data;
    } catch (err) {
        console.error(err);
    }
};

export const sendPutRequest = async(request, body) => {
    try {
        const resp = await axios.put(`${API_SERVER}${request}`, body);
        return resp.data;
    } catch (err) {
        console.error(err);
    }
}

axiosInt.interceptors.response.use(
  (response) => response,
  (error) => 
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    )
);

export const mock = new AxiosMockAdapter(axiosInt, { delayResponse: 0 });

export default axiosInt;
