import {lazy, Suspense} from "react";
import SuspenseLoader from "./components/SuspenseLoader";
import DrawerLayout from "./layouts/DrawerLayout";
import { PartialRouteObject } from 'react-router';
import Authenticated from "./components/Authenticated";

const Loader = (Component) => props => (
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
)

const Home = Loader(lazy(() => import ('src/content/Home')));
const Login = Loader(lazy(() => import('src/content/Login')));
const ConfirmResetPassword = Loader(lazy(() => import('src/content/ConfirmResetPassword')));
const ResetPassword = Loader(lazy(() => import('src/content/ResetPassword')));
const ProductSelect = Loader(lazy(() => import('src/content/ProductSelect')));
const Book = Loader(lazy(() => import('src/content/Book')));
const SignUp = Loader(lazy(() => import('src/content/SignUp')));
const ThankYou = Loader(lazy(() => import('src/content/ThankYou')));
const UserPage = Loader(lazy(() => import('src/content/User')));
const DeleteUser = Loader(lazy(() => import('src/content/DeleteUser')));
const EditUser = Loader(lazy(() => import('src/content/EditUser')));
const ChangePassword = Loader(lazy(() => import('src/content/EditUser/ChangePassword')));
const BookingDetail = Loader(lazy(() => import('src/content/BookingDetail')));
const DeleteBooking = Loader(lazy(() => import('src/content/DeleteBooking')));

const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));

const routes: PartialRouteObject[] = [
    {
        path: '*',
        element: (
            <DrawerLayout/>
        ),
        children: [
            {
                path: 'status',
                children: [
                    {
                        path: '/',
                        element: <Status404/>
                    },
                    {
                        path: '404',
                        element: <Status404/>
                    },
                    {
                        path: '500',
                        element: <Status500/>
                    }
                ]
            },
            {
                path: '*',
                element: <Status404/>
            }
        ]
    },
    {
        path: '/',
        element: (
            <DrawerLayout/>
        ),
        children: [
            {
                path: '/',
                element: <Home/>
            }
        ]
    },
    {
        path: 'select-product',
        element: (
            <DrawerLayout/>
        ),
        children: [
            {
                path: '/',
                element: <ProductSelect/>
            }
        ]
    },
    {
        path: 'book',
        element: (
            <DrawerLayout/>
        ),
        children: [
            {
                path: '/',
                element: <Book/>
            }
        ]
    },
    {
        path: 'confirm-reset-password',
        element: (
            <DrawerLayout/>
        ),
        children: [
            {
                path: '/',
                element: <ConfirmResetPassword/>
            }
        ]
    },
    {
        path: 'reset-password',
        element: (
            <DrawerLayout/>
        ),
        children: [
            {
                path: '/',
                element: <ResetPassword/>
            }
        ]
    },
    {
        path: 'login',
        element: (
            <DrawerLayout/>
        ),
        children: [
            {
                path: '/',
                element: <Login/>
            }
        ]
    },
    {
        path: 'sign_up',
        element: (
            <DrawerLayout/>
        ),
        children: [
            {
                path: '/',
                element: <SignUp/>
            }
        ]
    },
    {
        path: 'thank-you',
        element: (
            <DrawerLayout/>
        ),
        children: [
            {
                path: '/',
                element: <ThankYou/>
            }
        ]
    },
    {
        path: 'account',
        element: (
                <DrawerLayout/>
        ),
        children: [
            {
                path: '/',
                element: <UserPage/>
            },
            {
                path: '/edit',
                element: <EditUser/>
            },

            {
                path: '/edit/delete',
                element: <DeleteUser/>
            },
            {
                path: '/edit/change_password',
                element: <ChangePassword/>
            },
            {
                path: '/booking/:bookingId',
                element: <BookingDetail/>
            },
            {
                path: '/booking',
                element: <BookingDetail direct={true}/>
            },
            {
                path: '/booking/delete',
                element: <DeleteBooking/>
            }
        ]
    }
];

export default routes;
