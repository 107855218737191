import { FC, ReactNode, useState, useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { DrawerContext } from 'src/contexts/DrawerContext';

import PropTypes from 'prop-types';
import { Button, Collapse, ListItem } from '@mui/material';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';

interface DrawerMenuItemProps {
    children?: ReactNode;
    link?: string;
    overrideRouter?: boolean;
    openInBlank?: boolean;
    icon?: any;
    open?: boolean;
    active?: boolean;
    buttonClass?: string;
    name: string;
}

const DrawerMenuItem: FC<DrawerMenuItemProps> = ({
                                                     children,
                                                     link,
                                                     overrideRouter,
                                                     openInBlank,
                                                     icon: Icon,
                                                     open: openParent,
                                                     active,
    buttonClass,
                                                     name,
                                                     ...rest
                                                 }) => {
    const [menuToggle, setMenuToggle] = useState<boolean>(openParent);
    const { toggleDrawer } = useContext(DrawerContext);

    const toggleMenu = (): void => {
        setMenuToggle((Open) => !Open);
    };

    if (children) {
        return (
            <ListItem className="Mui-children" key={name} {...rest}>
                <Button
                    className={clsx({ 'Mui-active': menuToggle, 'menu-button-small': buttonClass })}
                    startIcon={Icon && <Icon />}
                    endIcon={
                        menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
                    }
                    onClick={toggleMenu}
                >
                    {name}
                </Button>
                <Collapse in={menuToggle}>{children}</Collapse>
            </ListItem>
        );
    }

    if (overrideRouter || openInBlank) {
        return (
            <ListItem key={name} {...rest}>
                <Button
                    className={clsx({ 'Mui-active': menuToggle, 'menu-button-small': buttonClass })}
                    component={RouterLink}
                    onClick={(e) => {e.preventDefault(); toggleDrawer(); window.open(link, "")}}
                    to={link}
                    startIcon={Icon && <Icon />}
                >
                    {name}
                </Button>
            </ListItem>
        );
    }

    return (
        <ListItem key={name} {...rest}>
            <Button
                activeClassName={active ? "active" : ""}
                className={clsx({'menu-button-small': buttonClass })}
                component={RouterLink}
                onClick={(e) => {e.preventDefault(); toggleDrawer(); window.open(link, "_self")}}
                to={link}
                startIcon={Icon && <Icon />}
            >
                {name}
            </Button>
        </ListItem>
    );
};

DrawerMenuItem.propTypes = {
    children: PropTypes.node,
    active: PropTypes.bool,
    link: PropTypes.string,
    overrideRouter: PropTypes.bool,
    openInBlank: PropTypes.bool,
    icon: PropTypes.elementType,
    open: PropTypes.bool,
    name: PropTypes.string.isRequired,
    buttonClass: PropTypes.string
};

DrawerMenuItem.defaultProps = {
    open: false,
    active: false,
    overrideRouter: false,
    openInBlank: false,
    buttonClass: undefined
};

export default DrawerMenuItem;
