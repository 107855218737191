import {ListSubheader, List, styled} from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import DrawerMenuItem from './item';
import {MenuItem, MenuItems} from './items';
import {FC, useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import * as process from "process";
import {matchPublicUrl} from "../../../../utils/matchPublicUrl";

const MenuWrapper = styled(List)(
    ({ theme }) => `
    margin-bottom: ${theme.spacing(1)};
    padding: 0;
    z-index: 9;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(2)} ${theme.spacing(2)};
    }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(13)};
      color: ${theme.drawer.menuItemHeadingColor};
      padding: ${theme.spacing(0.8, 2)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(List)(
    ({ theme }) => `
    &.MuiList-root {
      padding: 0;

      .MuiList-root .MuiList-root .MuiListItem-root .MuiButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 2px 15px;
    
        .MuiButton-root {
          display: flex;
          color: ${theme.drawer.menuItemColor};
          background-color: ${theme.drawer.menuItemBg};
          width: 100%;
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(30)};
          line-height: ${theme.typography.pxToRem(45)};
          font-weight: 700;
          padding-top: 10px;
          padding-bottom: 10px;
          position: relative;
          border-radius: 25px;

          .MuiBadge-root {
            position: relative;
            left: 20px;

            .MuiBadge-standard {
              background: #697ED5;
              font-size: ${theme.typography.pxToRem(12)};
              line-height: 26px;
              font-weight: 500;
              color: #ffffff;
            }
          }
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(26)};
            margin-right: ${theme.spacing(1.5)};
            color: ${theme.drawer.menuItemIconColor};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(22)};
          }

          &.active,
          &:hover {
            background-color: ${theme.drawer.menuItemBgActive};
            color: ${theme.drawer.menuItemColorActive};
            text-decoration-line: underline;

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.drawer.menuItemIconColorActive};
            }
          }
        }
        
        .MuiButton-root.menu-button-small {
            font-size: ${theme.typography.pxToRem(18)};
            line-height: ${theme.typography.pxToRem(27)};
        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px ${theme.spacing(0)};

            .MuiButton-root {
              font-size: ${theme.typography.pxToRem(13)};
              padding: ${theme.spacing(0.5, 2, 0.5, 6.5)};

              &.Mui-active,
              &:hover {
                background-color: ${theme.drawer.menuItemBg};
              }
            }
          }
        }
      }
    }
`
);

const renderDrawerMenuItems = ({
                                   items,
                                   path,
                               }: {
    items: MenuItem[];
    path: string;
}): JSX.Element => (
    <SubMenuWrapper>
        {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path}), [])}
    </SubMenuWrapper>
);

const reduceChildRoutes = ({
                               ev,
                               path,
                               item
                           }: {
    ev: JSX.Element[];
    path: string;
    item: MenuItem;
}): Array<JSX.Element> => {
    const key = item.name;

    let exactMatch = item.link ? !!matchPath({
        path: item.link,
        end: false
    }, path) : false;

    if (item.items) {
        const partialMatch = item.link ? !!matchPath({
            path: item.link,
            end: false
        }, path) : false;

        ev.push(
            <DrawerMenuItem
                key={key}
                active={partialMatch}
                open={exactMatch}
                overrideRouter={item.overrideRouter}
                openInBlank={item.openInBlank}
                name={item.name}
                icon={item.icon}
                link={item.link}
                buttonClass={item.buttonClass}
            >
                {renderDrawerMenuItems({
                    path,
                    items: item.items
                })}
            </DrawerMenuItem>
        );
    } else {
        if (!matchPublicUrl(path) && item.link === '/') {
            // Home.
            exactMatch = false;
        }

        ev.push(
            <DrawerMenuItem
                key={key}
                active={exactMatch}
                overrideRouter={item.overrideRouter}
                openInBlank={item.openInBlank}
                name={item.name}
                link={item.link}
                icon={item.icon}
                buttonClass={item.buttonClass}
            />
        );
    }

    return ev;
}

interface DrawerMenuProps {
    menuItems: MenuItems[];
}

const DrawerMenu:FC<DrawerMenuProps> = ({menuItems}) => {
    const location = useLocation();
    const auth = useAuth();

    return (
        <>
            {menuItems.map((section, index) => {
                const local = !auth.isAuthenticated ? section.items : section.items.filter(el => el.link !== '/sign_up');

                return (<MenuWrapper
                    key={section.heading}
                    subheader={
                        <ListSubheader sx={{marginLeft: "32px", marginRight: "32px", borderTop: (index === 0 ? "none" : "1px solid #D9D9D9")}} disableSticky>{section.heading}</ListSubheader>
                    }
                >
                    {renderDrawerMenuItems({
                        items:  local,
                        path: location.pathname
                    })}
                </MenuWrapper>)
})}
        </>
    );
}

export default DrawerMenu;
