import {FC, ReactNode} from 'react';
import {Box, styled} from '@mui/material';
import { Outlet } from 'react-router-dom';
import DrawerIndex from "./Drawer";
import Header from "./Header";
import Footer from "../../components/Footer";

interface DrawerLayoutProps {
    children?: ReactNode;
}

const MainWrapper = styled(Box)(
    ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: 0;
        }
`
);

const MainContent = styled(Box)(
    ({ theme }) => `
        flex: 1 1 auto;
        overflow: auto;
`
);

const DrawerLayout: FC<DrawerLayoutProps> = () => {

    return (
        <>
            <DrawerIndex />
            <MainWrapper>
                <Header />
                <MainContent id={"root-container"}>
                    <Outlet />
                </MainContent>
            </MainWrapper>
            <Footer marginTop={60}/>
        </>
    );
};

export default DrawerLayout;
